<template>
  <div class="gap"></div>
  <div class="one-exam">
    <a-tabs v-model:activeKey="activeKey" size="large">
      <!-- 我的考试 -->
      <a-tab-pane :key="1" :tab="$t('mine.my_exam')">
        <exam />
      </a-tab-pane>
      <!-- 考试批阅 -->
      <a-tab-pane :key="2" :tab="$t('exam_approval')" v-if="showReview">
        <examreview />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import { getReviewList } from "@/api/exam";
import exam from './components/exam.vue';
import examreview from './components/review/index.vue';

const store = useStore();

const activeKey = ref(1);
const showReview = ref(false);
const getReviiew = () => {
  getReviewList({ status: 0 }).then((res) => {
    if (res.data.list) {
      showReview.value = true; // 考试批阅
    }
  });
}
watch(
  () => store.getters.companyInfo,
  (val) => {
    if (val.menu && val.menu.includes('examreview')) {
      showReview.value && getReviiew();
    }
  },
  { immediate: true },
);
</script>
<style lang="less" scoped>
.gap {
  height: 20px;
}
.one-exam {
  min-height: 800px;
  width: 1000px;
  margin: 0 auto;
  background: #fff;
  border-radius: 6px;
  padding: 24px 27px;
}
</style>